import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { countries } from 'src/app/data/countries';
import { randomPerms } from 'src/app/data/perms';
import { Country } from 'src/app/interfaces/interfaces';
import { DialogsService } from 'src/app/services/dialogs.service';

interface Game {
  date: string,
  state: string,
  code: string,
  gameNumber: number,
  stateGame: number,
  photoCode: number,
  mainImage: string,
  landmarkImage: string,
  cityImage: string,
  cityName: string,
  allCities: string[]
  landmarkName: string,
  mapImage: string
  otherLandmarks: LandmarkRound[],
  landmarkPlaces: string[],
  landmarkCheck: boolean,
  cityCheck: boolean,
  past: boolean,
  mainGameName: boolean,
  gameUrl: string,
  capitalCity: string,
  mainImageLink: string,
  mainWikiLink: string,
  landmarkImageLink: string,
  landmarkWikiLink: string,
  cityImageLink: string,
  cityWikiLink: string,
  hasMapAndCityRound: boolean,
}

interface LandmarkRound {
  code: string,
  number: number
}

@Component({
  selector: 'app-admin-area',
  templateUrl: './admin-area.component.html',
  styleUrls: ['./admin-area.component.scss']
})
export class AdminAreaComponent implements OnInit {

  pages = [true, false, false, false]
  games: Game[] = [];
  perms = randomPerms;
  states = countries;
  showUpcoming: boolean = true;
  page$: BehaviorSubject<string> = new BehaviorSubject("upcoming");
  gameByState$: BehaviorSubject<Country> = new BehaviorSubject(countries[0]);
  stateCode: string = 'ak';
  showMap: boolean = true;
  statePlusCode: string = "";
  capital: string = "";

  cityCheck: boolean = false;
  errors: string[] = [];
  mainCheck: boolean = false;
  nicknameCheck: boolean = false;
  factCheck: boolean = false;
  flowerCheck: boolean = false;
  weatherCheck: boolean = false;
  landmarkCheck: boolean = false;

  constructor(private dialog: DialogsService) { }

  enlargeImage(val: string) {
    this.dialog.updateImage(val);
    this.dialog.openEnlargeImage();
  }

  togglePage(page: number) {
    for (let i = 0; i < this.pages.length; i++) {
      this.pages[i] = false;
    }
    this.pages[page] = true;

    switch (page) {
      case 0: {
        this.populateGames(true)
        break;
      }
      case 1: {
        this.populateGames(false)
        break
      }
      case 3: {
        this.changeMap("Alaska");
        break;
      }
      case 4: {
        this.games = [];
        this.runChecks()
      }
    }
  }

  runChecks() {
    const states = countries;

    // Check if each game in countries.ts has a main image with a name, imageLink and wikiLink
    this.cityCheck = true;
    this.mainCheck = true;
    this.nicknameCheck = true;
    this.factCheck = true;
    this.flowerCheck = true;
    this.weatherCheck = true;
    this.landmarkCheck = true;
    states.forEach(state => {
      let found = false;
      this.perms.forEach(perm => {
        if (perm.country === state.name) {
          found = true;
        }
      });
      if (state.cities.length < 6 && state.name !== 'Washington D.C' && found) {
        this.errors.push(`Missing cities for ${state.name}`);
        this.cityCheck = false;
      }

      state.game.forEach((game, i) => {

        let found = false;
        this.perms.forEach(perm => {
          if (perm.photoCode === game.number && perm.country === state.name) {
            found = true;
          }
        });

        if(state.name === "Cape Verde") {
          console.log("American Samoa");
        }

        if (found) {
          if (game.hasMapAndCityRound) {
            if (game.city.name === "") {
              this.errors.push(`Missing city name for ${state.name} (Game ${i + 1})`);
              this.cityCheck = false;
            }
            if (!state.cities.includes(game.city.name)) {
              this.errors.push(`City name for ${state.name} (Game ${i + 1}) is not in the list of cities`);
              this.cityCheck = false;
            }

            if(!state.cities.includes(state.capital)) {
              this.errors.push(`Capital city for ${state.name} is not in the list of cities`);
              this.cityCheck = false;
            }

            if (game.city.imageLink === "") {
              this.errors.push(`Missing city image link for ${state.name} (Game ${i + 1})`);
              this.cityCheck = false;
            }
            if (game.city.wikiLink === "") {
              this.errors.push(`Missing city wikipedia link for ${state.name} (Game ${i + 1})`);
              this.cityCheck = false;
            }
          }
          if (game.mainImage.imageLink === "") {
            this.errors.push(`Missing mainImage image link for ${state.name} (Game ${i + 1})`);
            this.mainCheck = false;
          }
          if (game.mainImage.wikiLink === "") {
            this.errors.push(`Missing mainImage wikipedia link for ${state.name} (Game ${i + 1})`);
            this.mainCheck = false;
          }

          // // Landmarks check
          if ((game.landmark.name === "" || game.landmark.imageLink === "" || game.landmark.wikiLink === "")) {
            this.errors.push(`Landmark round missing data for ${state.name} (Game ${i + 1})`);
            this.landmarkCheck = false;
          }

          if (game.landmarksRound.length !== 3 || this.hasDuplicates(game.landmarksRound.map(v => v.code + v.number))) {
            this.errors.push(`Landmarks round has duplicate answers for ${state.name} (Game ${i + 1})`);
            this.landmarkCheck = false;
          }

          if(game.landmarkPlaces.includes(game.landmark.name)) {
            this.errors.push(`Landmark round has duplicate answers for ${state.name} (Game ${i + 1})`);
            this.landmarkCheck = false;
          }

          if (game.landmarkPlaces.length !== 7 || this.hasDuplicates(game.landmarkPlaces) || game.landmarkPlaces.includes(game.landmark.name)) {
            this.errors.push(`Landmarks round has duplicate answers for ${state.name} (Game ${i + 1})`);
            this.landmarkCheck = false;
          }
        }
      });
    });
  }

  hasDuplicates(array: string[]) {
    return (new Set(array)).size !== array.length;
  }

  changeMap(stateName: string) {
    const state = this.states.find(v => v.name === stateName);
    if (state) {
      this.showMap = false;
      this.stateCode = state.code;
      this.statePlusCode = stateName + " (" + state.code.toUpperCase() + ")";
      this.capital = state.capital;
    }
    setTimeout(() => {
      this.showMap = true;
    }, 50)
  }

  ngOnInit(): void {
    this.populateGames(true);
  }

  populateGames(upcoming: boolean) {
    this.games = [];

    const date = new Date();
    const dateString = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    let start = false;
    if (!upcoming) {
      start = true;
    }
    let count = 0;
    this.perms.forEach(perm => {
      const photoCode = perm.photoCode;
      if (start) {
        const state = this.states.find(v => v.name === perm.country);
        // if (state && count < 45) {
        if (state) {
          let landmarkCheck = state.game[photoCode - 1].landmarkPlaces.length === 7 ? true : false;
          let cityCheck = state.cities.length >= 6;

          this.games.push({
            date: perm.date,
            gameNumber: perm.number,
            stateGame: 0,
            photoCode: photoCode,
            code: state.code.toLowerCase(),
            state: state.name,
            mainImage: `./assets/countries/${state.code.toLowerCase()}/main${photoCode}.webp`,
            landmarkImage: `./assets/countries/${state.code.toLowerCase()}/landmark${photoCode}.webp`,
            cityImage: `./assets/countries/${state.code.toLowerCase()}/city${photoCode}.webp`,
            mapImage: `./assets/countries/${state.code.toLowerCase()}/map.svg`,
            otherLandmarks: state.game[photoCode - 1].landmarksRound,
            landmarkPlaces: state.game[photoCode - 1].landmarkPlaces,
            cityName: state.game[photoCode - 1].city.name,
            landmarkName: state.game[photoCode - 1].landmark.name,
            landmarkCheck: landmarkCheck,
            cityCheck: cityCheck,
            past: false,
            mainGameName: true, // unused
            allCities: state.cities,
            gameUrl: `/?game=${state.game[photoCode - 1].uniqueId}`,
            capitalCity: state.capital,
            mainImageLink: state.game[photoCode - 1].mainImage.imageLink,
            mainWikiLink: state.game[photoCode - 1].mainImage.wikiLink,
            landmarkImageLink: state.game[photoCode - 1].landmark.imageLink,
            landmarkWikiLink: state.game[photoCode - 1].landmark.wikiLink,
            cityImageLink: state.game[photoCode - 1].city.imageLink,
            cityWikiLink: state.game[photoCode - 1].city.wikiLink,
            hasMapAndCityRound: state.game[photoCode - 1].hasMapAndCityRound,
          });
        }
        count++;
      }
      if (perm.date === dateString) {
        start = true;
      }
      if (!upcoming && perm.date === dateString) {
        start = false;
      }
    });
    if (!upcoming) {
      this.games = this.games.reverse();
    }
  }

  copyToClipboard(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = document.head.baseURI.substring(0, document.head.baseURI.length - 1) + val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

}
