<div class="mt-3 text-black dark:!text-white flex flex-col gap-3">
  <a (click)="navigateBack();" class="text-blue-500 hover:text-blue-400 cursor-pointer">&#8592; Back</a>

  <h4>
    <b>Welcome to WHERE<span class="text-blue-600 dark:text-blue-300">TAKEN🌎</span>.</b>
  </h4>

  <p>
    The aim of the game is to guess what country/region the photo was taken in. <a routerLink="/about" class="text-blue-500 hover:text-blue-400 cursor-pointer">Learn more</a>.
  </p>

  <p>
    If you guess incorrectly, the game will tell you how far away you are, and
    displays an arrow e.g. ➡️, that points in the direction of the correct
    country/region.
  </p>

  <p>A new
    <b>WHERE<span class="text-blue-600 dark:text-blue-300">TAKEN</span>🌎</b>
    will be available everyday!
  </p>
  <p>Play our US States version of
    <b>
      <a class="underline" href="https://wheretakenusa.teuteuf.fr" target="_blank">WHERE<span class="text-blue-600 dark:text-blue-300">TAKEN🇺🇸</span></a>
    </b>
  </p>
  <hr>
  <p class="font-bold">Any questions or suggestions?</p>
  <p>Check out the <a class="underline" href="https://worldle-assets.pages.dev/wheretaken-faq" target="_blank"><b>WHERE<span class="text-blue-600 dark:text-blue-300">TAKEN</span>🌎</b> FAQ</a></p>
  <hr>
  <p>Made by Ryan and Praveen - Follow us <a class="text-blue-600" href="https://twitter.com/wheretakengame" target="_blank">@wheretakengame</a></p>
  <p>Managed by <a href="https://thewordfinder.com" target="_blank" class="underline text-blue-600">The Word Finder</a> - Ads by <a href="https://snigel.com" target="_blank" class="underline text-blue-600">Snigel</a></p>
  <hr>
  <div>
    <a class="underline" href="https://teuteuf.fr/privacy.html" target="_blank">Privacy Policy</a> &nbsp;-&nbsp; <a class="underline" href="https://docs.google.com/forms/d/e/1FAIpQLSe-UDln8IMcm53NHYPt2m_a0XDyW1b6SCt8d-wxEmqYcMxYqw/viewform" target="_blank">Feedback</a>
  </div>
  <div *ngIf="isAdmin">
    <a routerLink="/admin/portal">Admin</a>
  </div>
</div>
